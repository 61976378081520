<template>
  <div class="setRight pl20 pt40">
    <user bg-color="#F1F1F1"></user>
    <div class="investigation text-left mt30" v-if="hotCamps.length">
      <div class="layer cup" v-for="(item, index) in hotCamps" :key="index">
        <div class="hide2 f22 c333 mb5" @click="goAvation(item)">
          {{ item.name }}
        </div>
        <div class="hide2 f16 c333 mt8" @click="goAvation(item)">
          {{ item.subName }}
        </div>
        <div class="dest mt25 mb10" v-if="item.pics && item.pics.length">
          <swiper :options="swiperOptions">
            <swiper-slide v-for="(dt, index) in item.pics" :key="index">
              <div @click="goAvation(item)">
                <img :src="dt.url" alt="" class="moreImg" />
                <!-- <div class="fweight pt15">
                    <div class="f20 c333">{{dt.subtitle}}</div>
                    <div class="f28 c333 pt10">{{dt.name}}</div>
                  </div> -->
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="dest mt25 mb15" v-else @click="goAvation(item)">
          <img :src="item.pic" class="alone" />
        </div>
        <div class="wBtn f12 cup" v-if="item.joinQty >= item.maxQty">
          已报满
        </div>
        <div class="wBtn f12 cup" v-else @click.stop="join(item)">
          {{ item.isJoin ? '已报名' : '一键报名' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from './user.vue'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
export default {
  name: '',
  components: {
    User
  },
  data() {
    return {
      hotCamps: '',
      swiperOptions: {
        autoplay: {
          delay: 50000,
          disableOnInteraction: false
        },
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: '.swiper-pagination', //与slot="pagination"处 class 一致
          clickable: true //轮播按钮支持点击
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.stateUser
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      var res = await this.$api.get('/api/project_index/index')
      console.log(res, 'res')
      if (res.code == 0) {
        var data = res.data
        this.hotCamps = data.hotCamps
      }
    },
    // 一键报名
    async join(item) {
      if (!this.user) return this.$bus.$emit('isLogin')
      if (item.isJoin) return

      if (!item.isJoin) {
        var startTime = new Date(
          new Date(item.bmStartDate).toLocaleDateString()
        )
          .getTime()
          .toString()
          .substring(0, 10)
        var endTime = (
          new Date(new Date(item.bmEndDate).toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
        )
          .toString()
          .substring(0, 10)
        var nowTime = new Date()
          .getTime()
          .toString()
          .substring(0, 10)
        if (Number(startTime) > Number(nowTime))
          return this.$message.warning('暂未到报名时间')
        if (Number(endTime) < Number(nowTime))
          return this.$message.warning('报名时间已结束')
      }

      var res = await this.$api.post('/api/project_camp/join', { id: item.id })
      if (res.code == 0) {
        this.$message.success('报名成功')
        item.isJoin = true
        //初始化当前用户报名了多少个活动
        this.$store.dispatch('setNumModal')
      }
    },
    goAvation(item) {
      var id = item.id
      this.$router.push('/home/detail?id=' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.setRight {
  width: 29%;
  min-width: 304px;
  height: 100%;
  background-color: #f6f9fd;
  margin-left: 8px;
  .investigation {
    overflow: auto;
    height: calc(100% - 130px);
    .layer {
      padding-top: 40px;
      padding-right: 10px;
      line-height: 1.2;
      .hide2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .wBtn {
        text-align: center;
        width: 100%;
        height: 30px;
        line-height: 30px;
        background-color: #ffe700;
        color: #500125;
        border-radius: 6px;
      }
      .dest {
        width: 100%;
        // height: 272px;
        background-color: #eeeeee;
        text-align: center;
        // line-height: 272px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        /deep/ .swiper-wrapper {
          position: static;
        }
        .alone {
          width: 100%;
          height: 300px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .moreImg {
          width: 100%;
          // height: 300px;
          vertical-align: middle;
          cursor: pointer;
        }
        .swiper-pagination {
          bottom: 0;
          height: 20px;
          text-align: right;
          width: auto;
          right: 0;
          display: inline-flex;
          left: inherit;
          /deep/ .swiper-pagination-bullet {
            background: #ffffff;
            opacity: 0.5;
          }
          /deep/ .swiper-pagination-bullet-active {
            background: #ffffff;
            opacity: 1;
          }
        }
        .fweight {
          & > div {
            font-weight: bold;
          }
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
</style>
